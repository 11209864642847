import AddCircleIcon from '@mui/icons-material/AddCircle';
import CircleIcon from '@mui/icons-material/Circle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { MapContainer, Marker, useMap, useMapEvents } from 'react-leaflet';
import * as consts from '../../consts';
import MapComponent from './map_component';

import { divIcon } from "leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';
import { getOnlineIndicatorClass } from '../../utils';
import moment from 'moment';
import ValueUnderMouse from '../../leaflet-heatmap/components/ValueUnderMouse';
import LeafletHeatmap from '../../leaflet-heatmap/components/LeafletHeatmap';
import LeafletConcentrationMap, { defaultConcentrationLevels } from '../../leaflet-heatmap/components/LeafletConcentrationMap';
import { degToRad } from '../admin/seismic/utils';
import { useWeatherStationLastValues } from '../../leaflet-heatmap/hooks/useWeatherStationLastValues';

const VODOKANAL_POSITION = {
	lat: 61.73308223570758, lng: 34.50027439453266
}

const VODOKONAL_METEOSTATION_ID = 1415;

function Heatmap(props) {
	const [valueUnderMouse, setValueUnderMouse] = useState(undefined);

	if (!props.heatmapConfig) {
		return <></>
	}

	return <>
		<div className='absolute z-[510] bottom-14 left-1/2 -translate-x-1/2'>
			<ValueUnderMouse value={valueUnderMouse} />
		</div>

		<LeafletHeatmap
			min={props.heatmapConfig.min}
			max={props.heatmapConfig.max}
			data={props.heatmapConfig.data}
			onUpdateMouseValue={setValueUnderMouse} />
	</>
}

function Map(props) {
	const [map, setMap] = useState(undefined);
	const sats = L.tileLayer("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", { attribution: '', subdomains: ['mt1', 'mt2', 'mt3'] })
	const osm = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", { attribution: '' })
	const layers = {
		'osm': osm,
		'sats': sats,
	}

	const [zoom, setZoom] = useState(15)
	const [mapCenter, setMapSenter] = useState([61.84329925920227, 34.385385217494985])
	const [hasZoom, setHasZoom] = useState(false)
	const [accountFetched, setAccountFetched] = useState(false)
	const [openDevice, setOpenDevice] = useState(undefined)

	const addControlPlaceholders = (map) => {
		var corners = map._controlCorners,
			l = 'leaflet-',
			container = map._controlContainer;

		function createCorner(vSide, hSide) {
			var className = l + vSide + ' ' + l + hSide;
			corners[vSide + hSide] = L.DomUtil.create('div', className, container);
		}

		createCorner('verticalcenter', 'left');
		createCorner('verticalcenter', 'right');
	}

	useEffect(() => {
		if (props.currentLayer) {
			setMapLayer(props.currentLayer)
		}
	}, [props.currentLayer]);


	useEffect(() => {
		if (map) {
			props.changeMapPosition?.()
		}
	}, [map]);


	useEffect(() => {
		const settings = props.accountSettings
		if (!settings) return;

		if (settings.map_zoom) {
			setZoom(settings.map_zoom)
		}
		if (settings.map_center_lat && settings.map_center_lng) {
			setMapSenter([settings.map_center_lat, settings.map_center_lng])
		}
		const layer = settings.map_layer ? settings.map_layer : 'osm'
		setMapLayer(layer)

		setAccountFetched(true)

	}, [props.accountSettings]);


	const setMapLayer = (layer) => {
		if (!map) return

		const _layer = layers[layer] ? layer : undefined;
		if (_layer) {
			map.addLayer(layers[_layer])
		}
	}

	const CreateCorners = () => {
		const map = useMap()
		addControlPlaceholders(map);
		return null
	}

	const getIconForDevice = (device) => {
		let isSelected = false;
		props.selectedDevices.forEach((d) => {
			if (d.device_id == device.device_id) {
				isSelected = true
			}
		});

		if (props.compareMode && consts.getInfoForDevice(device).comparable === true) {
			if (isSelected)
				return RemoveCircleIcon
			else
				return AddCircleIcon
		}

		return CircleIcon

	}

	const iconMarkup = (device) => {
		const mode = getOnlineIndicatorClass(device)
		const Icon = getIconForDevice(device)

		return renderToStaticMarkup(
			<div className={`widget-data-indicator-${mode}`}>
				<Icon className="text-xs" />
			</div>
		);
	}


	const customMarkerIcon = (device) => {
		return divIcon({
			html: iconMarkup(device)
		});
	}


	const ShowDeviceOnMap = () => {
		const map = useMap()

		// Remove #close anchor
		const currentUrl = window.location.href
		if (currentUrl.endsWith('#close')) {
			console.log('')
			window.history.replaceState(null, '', currentUrl.replace('#close', ''))
		}


		if (!props.deviceOnMap) {
			map.closePopup()
			return null;
		}

		if (props.deviceOnMap && openDevice && props.deviceOnMap.device_id == openDevice.device_id) {
			return null;
		}

		openPopup(props.deviceOnMap)


		if (props.deviceToMapCenter) {
			map.flyTo(new L.LatLng(props.deviceOnMap.latitude, props.deviceOnMap.longitude), 17)
		}
		setOpenDevice(props.deviceOnMap)
		return null;
	}

	const AccountSettingsLoading = ({ mapZoom, mapCenter }) => {
		const map = useMap()
		if (!accountFetched || hasZoom) return null;
		map.setView(mapCenter, mapZoom);
		setHasZoom(true)
		return null
	}

	const MapChangesComponent = () => {
		const mapEvents = useMapEvents({
			zoomend: () => {
				props.changeMapPosition?.(mapEvents.getZoom(), mapEvents.getCenter())
			},
			moveend: () => {
				props.changeMapPosition?.(mapEvents.getZoom(), mapEvents.getCenter())
			},
		});

		return null;
	}

	const openPopup = (device) => {
		const lastTime = device.last_update_datetime ? moment(device.last_update_datetime).format('DD-MM-YYYY HH:mm:SS') : ''

		let popupContent = `<div style="font-family: Montserrat;">`
			+ `${lastTime}<br />`
			+ `<b>${device.getFullName()}</b> <br />`
			+ `<span style="color:#508104;">${device.location_description}</span> <br />`;


		let parameters = consts.getInfoForDevice(device).parameters

		if (parameters) {
			const parameterKeys = Object.keys(parameters)
			if (parameterKeys.length > 0) {
				popupContent += '<p>';
				parameters[parameterKeys[0]]['keys'].forEach((key) => {
					let value = device.last_parameter_values[key];
					if (!value) value = '--'
					const module_type_id = device.module_type_id ? device.module_type_id : '';
					const paramKey = `${device.device_type_id}_${module_type_id}_${key}`
					const param = props.deviceTypeParameters[paramKey]

					if (param) {
						const row = `${param.label}: ${value} ${param.units}`;
						popupContent += row + '<br />';
					}
				})
				popupContent += '</p>';
			}
		}
		popupContent += `</div>`

		const marker = L.popup({ offset: L.point(0, 0) })
			.setLatLng([device.latitude, device.longitude])
			.setContent(popupContent)
			.openOn(map)
	}

	const weatherStationsLastValues = useWeatherStationLastValues(VODOKONAL_METEOSTATION_ID);

	return (
		<MapContainer
			center={mapCenter}
			zoom={zoom}
			maxZoom={25}
			scrollWheelZoom={true}
			attributionControl={false}
			className="map-container"
			zoomControl={false}
			ref={(ref) => setMap(ref)}
		>
			<Heatmap heatmapConfig={props.heatmapConfig} />

			{props?.concentrationModelParams?.show
				&& weatherStationsLastValues
				&& <LeafletConcentrationMap
							levels={props.concentrationModelParams.levels}
							parameters={{
								...props.concentrationModelParams,
								U: weatherStationsLastValues.windSpeed,
								directionAngle: degToRad(weatherStationsLastValues.windDirection) + Math.PI,
							}}
							position={VODOKANAL_POSITION}
						/>}

			<CreateCorners />
			<ShowDeviceOnMap />
			<AccountSettingsLoading mapZoom={zoom} mapCenter={mapCenter} />
			<MapChangesComponent />
			<MarkerClusterGroup
				chunkedLoading
				maxClusterRadius={10}
				animate={true}
			>
				{props.devices.map((device, i) => {
					return <Marker key={`device_marker_${i}`} position={[device.latitude, device.longitude]}
						icon={customMarkerIcon(device)}
						eventHandlers={{
							click: (e) => {
								props.deviceClick(device)
							},
							mouseover: (e) => {
								openPopup(device)
							},
							mouseout: (e) => {
								map.closePopup()
							}
						}}
					>
					</Marker>
				})}
			</MarkerClusterGroup>

			<MapComponent
				key={`key_mpanel_${props.classShowMonitoringPanel}_${props.compareMode}`}
				onClick={props.toggleMonitoringPanel}
				evnt="click"
				element={() => {
					const element = L.DomUtil.create("button", `btn btn-light btn-mpanel`);
					element.innerHTML = `<img class="inline" src="/static_map/triangle_right.png">`
					return element
				}}
				position="topleft"
			/>

			{props.children}
		</MapContainer>
	);
}

export default Map;

